import { buildSchema, buildProperty } from "@camberi/firecms";

export const sessionsSchema = buildSchema({
    name: "Sessions",
    properties: {
        coach: {
            title: "Coach Name",
            description: "Coach Details",
            // dataType: "reference",
            // path: "coaches",
            // previewProperties: ["firstName", "lastName"]
            dataType: "map",
            properties: {
                firstName: {
                    title: "firstName",
                    dataType: "string"
                },
                lastName: {
                    title: "lastName",
                    dataType: "string"
                },
            }
        },
        sessionName: {
            title: "Session Name",
            dataType: "string"
        },
        description: {
            title: "Description",
            dataType: "string"
        },
        category: {
            title: "Category",
            description: "Category Details",
            dataType: "map",
            properties: {
                categoryName: {
                    title: "Category",
                    dataType: "string"
                }
            }
        },
        channelName: {
            title: "Channel Name",
            dataType: "string"
        },
        savedVideoUrl: {
            title: "Saved Video Url",
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "video",
                    storagePath: "videos",
                    acceptedFiles: ["video/*"],
                    storeUrl: true,
                    metadata: {
                        cacheControl: "max-age=1000000"
                    }
                }
            }
        },
        externalLiveUrl: {
            title: "External Live URL",
            dataType: "string"
        },
        startDate: {
            title: "Start Date",
            dataType: "string"
        },
        startedAt: {
            title: "Started At",
            dataType: "timestamp"
        },
        endedAt: {
            title: "Ended At",
            dataType: "timestamp"
        },
        hasEnded: {
            title: "Has Ended",
            dataType: "boolean"
        },
        savedVideo: {
            title: "Saved Video",
            dataType: "boolean"
        },
        isArchived: {
            title: "Is Archived",
            dataType: "boolean"
        },
        status: {
            title: "Status",
            dataType: "string",
            config: {
                enumValues: {
                    waiting: "Waiting",
                    open: "Open",
                    live: "Live",
                    finished: "Finished",
                    closed: "Closed",
                }
            }
        },
        sessionType: buildProperty({
            title: "Type",
            validation: { required: true },
            dataType: "number",
            columnWidth: 140,
            config: {
                enumValues: {
                    1: "Live",
                    0: "Test",
                }
            },
        }),
    },
    defaultValues: {
        sessionType: 1,
    }
});

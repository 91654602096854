import { buildSchema,buildProperty } from "@camberi/firecms";

export const workoutCategoriesSchema = buildSchema({
    name: "Workout Categories",
    properties: {
        categoryName: {
            title: "categoryName",
            dataType: "string",
            validation: { required: true },
        },
        description: {
            title: "description",
            dataType: "string",
            config: {
                markdown: true
            },
            validation: { required: true },
        },
        thumbnailUrl: buildProperty({
            title: "Thumbnail Image",
            description: "Recommended size (200 X 140)",
            dataType: "string",
            validation: { required: true },
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "images",
                    acceptedFiles: ["image/*"],
                    storeUrl: true,
                    metadata: {
                        cacheControl: "max-age=1000000"
                    }
                }
            }
        }),
        colour: buildProperty({
            title: "Colour",
            validation: { required: true },
            dataType: "string"
        }),
    },
    defaultValues: {
        colour: "E03CA3",
    }
});

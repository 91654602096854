import { buildSchema } from "@camberi/firecms";

export const subscriptionTiersSchema = buildSchema({
    name: "Subscription Tiers",
    properties: {
        name: {
            title: "Name",
            dataType: "string",
            disabled: true,
            validation: {required:true}
        },
        range_from: {
            title: "Range From",
            dataType: "number",
            disabled: true,
            validation: {required:true}
        },
        range_to: {
            title: "Range To",
            dataType: "number",
            disabled: true,
        },
        fee_amount: {
            title: "Fee Amount",
            dataType: "number",
            disabled: true,
            validation: {required:true}
        },
    }
});

import { buildSchema,buildProperty } from "@camberi/firecms";

export const linksSchema = buildSchema({
    name: "Links",
    properties: {
        title: {
            title: "Title",
            validation: { required: true },
            dataType: "string"
        },
        url: {
            title: "Url",
            validation: { required: true },
            dataType: "string"
        },
        thumbnailUrl: buildProperty({
            title: "Thumbnail Image",
            dataType: "string",
            description: "Recommended size (200 X 140)",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storeUrl: true,
                    storagePath: "images",
                    acceptedFiles: ["image/*"],
                    metadata: {
                        cacheControl: "max-age=1000000"
                    }
                }
            }
        }),
        code: {
            title: "Code",
            validation: { required: true },
            dataType: "number",
            columnWidth: 140,
            config: {
                enumValues: {
                    1000: "Facebook",
                    1001: "Twitter",
                    1002: "Instagram",
                    1003: "Youtube",
                    1004: "External Links",
                    1005: "Shop",
                    1006: "Forum",
                    1007: "Tiktok",
                }
            }
        },
        type: {
            title: "Type",
            validation: { required: true },
            dataType: "string",
            columnWidth: 140,
            config: {
                enumValues: {
                    socialMediaLink: "Social Media Link",
                    externalLink: "External Links"
                }
            }
        },
        status: {
            title: "Status",
            validation: { required: true },
            dataType: "boolean"
        },
        sort: {
            title: "Sort",
            validation: { required: true },
            dataType: "number"
        },
    }
});

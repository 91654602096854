import { buildSchema } from "@camberi/firecms";

export const subscriptionsSchema = buildSchema({
    name: "Subscriptions",
    properties: {
        items:{
            title: "Product",
            description: "Product Details",
            dataType: "array",
            of: {
                title: 'Item',
                dataType: 'map',
                properties: {
                    price : {
                        title: "Price",
                        dataType: "map",
                        properties: {
                            product: {
                                title: "Product",
                                dataType: "map",
                                properties: {
                                    name: {
                                        title: "Name",
                                        dataType: "string",
                                    }
                                }
                            }
                        }
                    }
                }
            },
            readOnly: true,
        },
        stripeLink:{
            title: "Stripe Link",
            dataType: "string",
            readOnly: true,
        },
        current_period_start:{
            title: "Start Date",
            dataType: "timestamp",
            readOnly: true,
        },
        current_period_end:{
            title: "End Date",
            dataType: "timestamp",
            readOnly: true,
        },
        created:{
            title: "Created",
            dataType: "timestamp",
            readOnly: true,
        },
        status: {
            title: "Status",
            dataType: "string",
            readOnly: true,
        }
    }
});

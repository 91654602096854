import { buildSchema } from "@camberi/firecms";

export const checkoutSessionsSchema = buildSchema({
    name: "Checkout Sessions",
    properties: {
        client: {
            title: "Client",
            dataType: "string",
            readOnly: true,
        },
        mode: {
            title: "Mode",
            dataType: "string",
            readOnly: true,
        },
        created: {
            title: "Created At",
            dataType: "timestamp",
            readOnly: true,
        },
        cancel_url: {
            title: "Cancel Url",
            dataType: "string",
            readOnly: true,
        },
        price: {
            title: "Price",
            dataType: "string",
            readOnly: true,
        },
        success_url: {
            title: "Success Url",
            dataType: "string",
            readOnly: true,
        },
        sessionId: {
            title: "Session Id",
            dataType: "string",
            readOnly: true,
        },
        url: {
            title: "Url",
            dataType: "string",
            readOnly: true,
        }
    }
});

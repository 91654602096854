import { buildSchema } from "@camberi/firecms";

export const paymentsSchema = buildSchema({
    name: "Payments",
    properties: {
        description:{
            title: "Description",
            dataType: "string",
            readOnly: true,
        },
        amount:{
            title: "Amount",
            dataType: "number",
            readOnly: true,
        },
        currency:{
            title: "Currency",
            dataType: "string",
            readOnly: true,
        },
        created:{
            title: "Created",
            dataType: "number",
            readOnly: true,
        },
        status: {
            title: "Status",
            dataType: "string",
            readOnly: true,
        }
    }
});

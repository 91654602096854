// Replace with your Firebase config
export const firebaseConfig = {
  apiKey: "AIzaSyDzfBnVB2n__LpcQO_cRE_yZgEYfx7zW-k",
  authDomain: "maeve-madden.firebaseapp.com",
  databaseURL: "https://maeve-madden-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "maeve-madden",
  storageBucket: "maeve-madden.appspot.com",
  messagingSenderId: "327021877703",
  appId: "1:327021877703:web:2cb6d55125d820bfd621e2",
  measurementId: "G-4L4SKYBRY5"
};
import { buildSchema } from "@camberi/firecms";

export const staticPagesSchema = buildSchema({
    name: "Static Pages",
    properties: {
        title: {
            title: "title",
            dataType: "string",
            validation: { required: true },
        },
        description: {
            title: "description",
            dataType: "string",
            config: {
                markdown: true
            },
            validation: { required: true },
        },
        // type: {
        //     title: "Type",
        //     dataType: "string",
        //     config: {
        //         enumValues: {
        //             privacy_policy: "Privacy & Policy",
        //             term_conditions: "Terms and Conditions",
        //         }
        //     }
        // }
    }
});

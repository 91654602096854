import { buildSchema } from "@camberi/firecms";

export const coachesSchema = buildSchema({
    name: "Coaches",
    properties: {
        firstName: {
            title: "firstName",
            dataType: "string"
        },
        lastName: {
            title: "lastName",
            dataType: "string"
        },
        canCreateToken: {
            title: "canCreateToken",
            dataType: "boolean"
        },
        coachLevel: {
            title: "coachLevel",
            dataType: "number"
        },
        isApproved: {
            title: "isApproved",
            dataType: "boolean"
        },
        createdAt: {
            title: "createdAt",
            dataType: "timestamp"
        },
    }
});

import React from "react";

import { initializeApp } from 'firebase/app';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { EmailAuthProvider, GoogleAuthProvider } from "firebase/auth";
import {
    buildCollection,
    FirebaseCMSApp,
    Navigation,
    NavigationBuilder,
    NavigationBuilderProps
} from "@camberi/firecms";

import { firebaseConfig } from "../firebase_config";
// import { ExampleCMSView } from "./ExampleCMSView";
import logo from "./images/remotecoach-logo.jpg";
import { textSearchController } from "./text_search";
// import {
//     localeSchema,
//     productAdditionalColumn, productCallbacks,
//     productExtraActionBuilder,
//     productSchema
// } from "./schemas/products_schema";

import { usersSchema, userAdditionalExportColumn, userAgeColumn, attendeesSchema } from "./schemas/users_schema";
import {
    blogSchema,
    sampleAdditionalExportColumn
} from "./schemas/blog_schema";
import {
    recipeSchema,
} from "./schemas/recipe_schema";
import {
    videoSchema,
} from "./schemas/video_schema";
import {
    coachesSchema,
} from "./schemas/coaches_schema";
import {
    workoutCategoriesSchema,
} from "./schemas/workout_categories_schema";
import {
    sessionsSchema,
} from "./schemas/sessions_schema";
// import {
//     upcomingSessionsSchema,
// } from "./schemas/upcoming_sessions_schema";
import {
    subscriptionsSchema,
} from "./schemas/subscriptions_schema";
import {
    paymentsSchema,
} from "./schemas/payments_schema";
import {
    checkoutSessionsSchema,
} from "./schemas/checkout_sessions_schema";
import {
    staticPagesSchema,
} from "./schemas/static_pages_schema";
import {
    subscriptionTiersSchema,
} from "./schemas/subscription_tiers_schema";
import {
    linksSchema,
} from "./schemas/links_schema";
import { customSchemaResolver } from "./schemas/custom_schema_resolver";

import "typeface-rubik";
import "typeface-space-mono";
// import { Locale, Product } from "./types";

function SampleApp() {
    // const productsCollection = buildCollection<Product>({
    //     path: "products",
    //     schema: productSchema,
    //     callbacks: productCallbacks,
    //     name: "Products",
    //     group: "Main",
    //     description: "List of the products currently sold in our shop",
    //     textSearchEnabled: true,
    //     additionalColumns: [productAdditionalColumn],
    //     filterCombinations: [{ category: "desc", available: "desc" }],
    //     permissions: ({ authController }) => ({
    //         edit: true,
    //         create: true,
    //         // we use some custom logic by storing user data in the `extra`
    //         // field of the user
    //         delete: authController.extra?.roles.includes("admin")
    //     }),
    //     extraActions: productExtraActionBuilder,
    //     subcollections: [localeCollection],
    //     excludedProperties: ["images"]
    // });

    const usersCollection = buildCollection({
        path: "users",
        schema: usersSchema,
        name: "Users",
        defaultSize: "xs",
        group: "Main",
        description: "Registered users",
        textSearchEnabled: true,
        additionalColumns: [
            {
                id: "sample_additional",
                title: "Sample additional",
                builder: () => "Content of a generated column"
            }
        ],
        properties: ["firstName", "lastName", "email", "currentHeight", "heightUnit", "timezone", "currentWeight", "weightUnit",
            "photoURL", "userGroup", "createdAt"],
        subcollections: [{
            name: "Subscriptions",
            schema: subscriptionsSchema,
            path: "subscriptions",
            properties: ["status", "items", "stripeLink", "current_period_start", "current_period_end", "created"],
        }, {
            name: "Payments",
            schema: paymentsSchema,
            path: "payments",
            properties: ["status", "description", "amount", "currency", "created"],
        }, {
            name: "Checkout Sessions",
            schema: checkoutSessionsSchema,
            path: "checkout_sessions",
            properties: ["client", "mode", "created", "cancel_url", "price", "success_url", "sessionId", "url"],
        }],
        exportable: false,
        // exportable: {
            // additionalColumns: [userAdditionalExportColumn, userAgeColumn]
        // },
        initialSort: ["createdAt", "desc" ],
    });

    const blogCollection = buildCollection({
        path: "blogs",
        schema: blogSchema,
        name: "Posts",
        group: "Main",
        exportable: {
            additionalColumns: [sampleAdditionalExportColumn]
        },
        defaultSize: "l",
        properties: ["name", "headerImage", "status", "content", "isFree", "reviewed", "publishedAt"],
        // description: "Collection of blog entries included in our [awesome blog](https://www.google.com)",
        description: "Collection of articles and posts",
        textSearchEnabled: true,
        initialFilter: {
            // "status": ["==", "published"]
        }
    });

    const recipeCollection = buildCollection({
        path: "recipes",
        schema: recipeSchema,
        name: "Recipes",
        group: "Main",
        exportable: {
            additionalColumns: [sampleAdditionalExportColumn]
        },
        defaultSize: "l",
        properties: ["name", "headerImage", "mealType", "calories", "carbohydrate", "fat", "protein", "serves", "ingredients", "directions", "status", "content", "reviewed", "publishDate"],
        // description: "Collection of blog entries included in our [awesome blog](https://www.google.com)",
        description: "Collection of recipes",
        textSearchEnabled: true,
        initialFilter: {
            // "status": ["==", "published"]
        }
    });

    const videoCollection = buildCollection({
        path: "video_uploads",
        schema: videoSchema,
        name: "Videos",
        group: "Main",
        exportable: {
            additionalColumns: [sampleAdditionalExportColumn]
        },
        defaultSize: "l",
        properties: ["videoName", "description", "category", "colour", "hlsExternalUrl", "thumbnailUrl", "externalUrl", "videoUrl", "isFree", "status", "publishDate"],
        // description: "Collection of blog entries included in our [awesome blog](https://www.google.com)",
        description: "Collection of free videos",
        textSearchEnabled: true,
        initialFilter: {
            // "status": ["==", "published"]
        },
        initialSort: ["publishDate", "desc" ],
    });

    const workoutCategoriesCollection = buildCollection({
        path: "workout_categories",
        schema: workoutCategoriesSchema,
        name: "Workout Categories",
        group: "Main",
        exportable: {
            additionalColumns: [sampleAdditionalExportColumn]
        },
        defaultSize: "xs",
        properties: ["categoryName", "description", "thumbnailUrl", "colour"],
        description: "List of workout categories",
        textSearchEnabled: true,
    });

    const coachesCollection = buildCollection({
        path: "coaches",
        schema: coachesSchema,
        name: "Coaches",
        group: "Main",
        exportable: {
            additionalColumns: [sampleAdditionalExportColumn]
        },
        defaultSize: "xs",
        properties: ["firstName", "lastName", "canCreateToken", "coachLevel", "isApproved", "createdAt"],
        // description: "Collection of blog entries included in our [awesome blog](https://www.google.com)",
        description: "List of coach in the system",
        textSearchEnabled: true,
        initialFilter: {
            // "status": ["==", "published"]
        }
    });

    const sessionsCollection = buildCollection({
        path: "sessions",
        schema: sessionsSchema,
        name: "Sessions",
        group: "Main",
        exportable: {
            additionalColumns: [sampleAdditionalExportColumn]
        },
        defaultSize: "xs",
        properties: ["coach", "sessionName", "description", "category", "channelName", "savedVideoUrl", "externalLiveUrl", "startDate", "startedAt", "endedAt", "isArchived", "status", "sessionType"],
        // description: "Collection of blog entries included in our [awesome blog](https://www.google.com)",
        description: "List of sessions",
        textSearchEnabled: true,
        initialSort: ["startDate", "desc" ],
        permissions: ({ authController }) => ({
            create: false,
        }),
        subcollections: [{
            name: "Users",
            schema: attendeesSchema,
            path: "attendees",
            properties: ["name", "hr", "calorie", "createdAt"],
            defaultSize: "xs",
            permissions: ({ authController }) => ({
                create: false,
            }),
        }],
    });

    // const upcomingSessionsCollection = buildCollection({
    //     path: "sessions",
    //     schema: upcomingSessionsSchema,
    //     name: "Upcoming Sessions",
    //     group: "Main",
    //     exportable: {
    //         additionalColumns: [sampleAdditionalExportColumn]
    //     },
    //     defaultSize: "xs",
    //     properties: ["coach","sessionName", "description", "category", "channelName", "savedVideoUrl", "startDate", "startedAt", "endedAt", "isArchived","status"],
    //     // description: "Collection of blog entries included in our [awesome blog](https://www.google.com)",
    //     description: "Upcoming sessions list",
    //     textSearchEnabled: true,
    //     initialFilter: {
    //         "startDate": ["!=", null],
    //         "isArchived": ["==", false],
    //         "status": ["in", ['waiting', 'open', 'live']],
    //     },
    //     filterCombinations: [{ startDate: "asc" }],
    // });

    // const pastSessionsCollection = buildCollection({
    //     path: "sessions",
    //     schema: sessionsSchema,
    //     name: "Past Sessions",
    //     group: "Main",
    //     exportable: {
    //         additionalColumns: [sampleAdditionalExportColumn]
    //     },
    //     defaultSize: "xs",
    //     properties: ["coach","sessionName", "description", "category", "channelName", "savedVideoUrl", "startDate", "startedAt", "endedAt", "isArchived","status"],
    //     // description: "Collection of blog entries included in our [awesome blog](https://www.google.com)",
    //     description: "Past sessions list",
    //     textSearchEnabled: true,
    //     initialFilter: {
    //         "savedVideo": ["==", true],
    //         "isArchived": ["==", false],
    //         "status": ["in", ['finished', 'closed']],
    //     },
    //     filterCombinations: [{ startDate: "desc" }],
    // });

    const staticPagesCollection = buildCollection({
        path: "static_pages",
        schema: staticPagesSchema,
        name: "Static Pages",
        group: "Main",
        exportable: {
            additionalColumns: [sampleAdditionalExportColumn]
        },
        defaultSize: "m",
        properties: ["title", "description"],
        // description: "Collection of blog entries included in our [awesome blog](https://www.google.com)",
        description: "List of Static Pages",
        textSearchEnabled: true,
        initialFilter: {
            // "status": ["==", "published"]
        }
    });

    const subscriptionTiersCollection = buildCollection({
        path: "subscription_tiers",
        schema: subscriptionTiersSchema,
        name: "Subscription Tiers",
        group: "Main",
        exportable: {
            additionalColumns: [sampleAdditionalExportColumn]
        },
        defaultSize: "xs",
        properties: ["name", "range_from", "range_to", "fee_amount"],
        description: "List of subscription tiers",
        textSearchEnabled: false,
        permissions: ({ authController }) => {
            // const isAdmin = authController.extra?.roles.includes("admin");
            return ({
                edit: false,
                create: false,
                delete: false
            })
        },
    });

    const linksCollection = buildCollection({
        path: "links",
        schema: linksSchema,
        name: "Links",
        group: "Main",
        exportable: {
            additionalColumns: [sampleAdditionalExportColumn]
        },
        defaultSize: "xs",
        properties: ["title", "url", "thumbnailUrl", "code", "sort", "type", "status"],
        description: "List of links",
        textSearchEnabled: true,
    });

    // const githubLink = (
    //     <Tooltip
    //         title="See this project on GitHub. This button is only present in this demo">
    //         <IconButton
    //             href={"https://github.com/Camberi/firecms"}
    //             rel="noopener noreferrer"
    //             target="_blank"
    //             component={"a"}
    //             size="large">
    //             <GitHub/>
    //         </IconButton>
    //     </Tooltip>
    // );

    // const customViews: CMSView[] = [{
    //     path: ["additional", "additional/:id"],
    //     name: "More Features",
    //     group: "Content",
    //     description: "Additional Views coming soon...",
    //     view: <ExampleCMSView/>
    // }];


    const onFirebaseInit = (config: Object) => {
        // Just calling analytics enables screen tracking
        getAnalytics();
    };

    // const myAuthenticator: Authenticator = async ({ user, authController }) => {
    //     // This is an example of retrieving async data related to the user
    //     // and storing it in the user extra field
    //     const sampleUserData = await Promise.resolve({
    //         roles: ["admin"]
    //     });
    //     authController.setExtra(sampleUserData);
    //     console.log("Allowing access to", user?.email);
    //     return true;
    // };

    const navigation: NavigationBuilder = async ({
        user,
        authController
    }: NavigationBuilderProps) => {
        const navigation: Navigation = {
            collections: [
                usersCollection,
                recipeCollection,
                blogCollection,
                videoCollection,
                workoutCategoriesCollection,
                coachesCollection,
                sessionsCollection,
                staticPagesCollection,
                linksCollection,
            ]
        };

        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const docRef = doc(db, "coaches", user!.uid);
        const docSnap = await getDoc(docRef);
        // let collections:EntityCollection[] = [];

        if (!docSnap.exists() || docSnap.data().isApproved === false) {
            authController.signOut();
        } else {
            if (docSnap.data().isAdmin) {
                const addRole = await Promise.resolve({
                    roles: ["admin"]
                });
                authController.setExtra(addRole);
                navigation.collections.push(buildCollection(subscriptionTiersCollection));
            }
        }
        return navigation;
    };

    return <FirebaseCMSApp
        name={"Maeve Madden - Base CMS"}
        // authentication={myAuthenticator}
        signInOptions={[
            GoogleAuthProvider.PROVIDER_ID,
            EmailAuthProvider.PROVIDER_ID
        ]}
        textSearchController={textSearchController}
        // allowSkipLogin={true}
        logo={logo}
        navigation={navigation}
        schemaResolver={customSchemaResolver}
        firebaseConfig={firebaseConfig}
        onFirebaseInit={onFirebaseInit}
    // toolbarExtraWidget={githubLink}
    />;
}

export default SampleApp;


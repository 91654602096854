import { buildSchema, buildProperty, ExportMappingFunction } from "@camberi/firecms";

/**
 * This is a schema that is generated without passing an explicit type,
 * but it is inferred correctly since we are using `buildProperty` in each property
 */
export const recipeSchema = buildSchema({
    name: "Recipe",
    // views: [{
    //     path: "preview",
    //     name: "Preview",
    //     builder: (props) => <BlogEntryPreview {...props}/>
    // }],
    properties: {
        name: buildProperty({
            title: "Name",
            validation: { required: true },
            dataType: "string"
        }),
        headerImage: buildProperty({
            title: "Header image",
            description: "Recommended size (200 X 140)",
            dataType: "string",
            validation: { required: true },
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "images",
                    storeUrl: true,
                    acceptedFiles: ["image/*"],
                    metadata: {
                        cacheControl: "max-age=1000000"
                    }
                }
            }
        }),
        mealType: buildProperty({
            title: "Meal Type",
            validation: { required: true },
            dataType: "string",
            columnWidth: 140,
            config: {
                enumValues: {
                    breakfast: "Breakfast",
                    liteMeals: "Lite Meals",
                    lunch: "Lunch",
                    dinner: "Dinner",
                }
            }
        }),
        serves: buildProperty({
            title: "Serves",
            // validation: { required: true },
            dataType: "number"
        }),
        prepTime: buildProperty({
            title: "Prep Time (min)",
            // validation: { required: true },
            dataType: "number"
        }),
        cookTime: buildProperty({
            title: "Cooking Time (min)",
            // validation: { required: true },
            dataType: "number"
        }),
        carbohydrate: buildProperty({
            title: "Carbohydrate (g)",
            // validation: { required: true },
            dataType: "number"
        }),
        protein: buildProperty({
            title: "Protein (g)",
            // validation: { required: true },
            dataType: "number"
        }),
        fat: buildProperty({
            title: "Fat (g)",
            // validation: { required: true },
            dataType: "number"
        }),
        calories: buildProperty({
            title: "Calories",
            // validation: { required: true },
            dataType: "number"
        }),
        ingredients: buildProperty({
            title: "Ingredients",
            description: "List of Ingredients",
            validation: { required: true },
            dataType: "array",
            of: {
                dataType: "string",
                config: {
                    // previewAsTag: true
                }
            }
        }),
        directions: buildProperty({
            title: "Directions",
            description: "List of Directions",
            validation: { required: true },
            dataType: "array",
            of: {
                dataType: "string",
                config: {
                    // previewAsTag: true
                }
            }
        }),
        content: buildProperty({
            title: "Content",
            description: "Example of a complex array with multiple properties as children",
            validation: { required: true },
            dataType: "array",
            columnWidth: 400,
            oneOf: {
                properties: {
                    images: {
                        title: "Images",
                        dataType: "array",
                        validation: { required: true },
                        of: {
                            dataType: "string",
                            config: {
                                storageMeta: {
                                    mediaType: "image",
                                    storagePath: "images",
                                    acceptedFiles: ["image/*"],
                                    storeUrl: true,
                                    metadata: {
                                        cacheControl: "max-age=1000000"
                                    }
                                }
                            }
                        },
                        description: "This fields allows uploading multiple images at once and reordering"
                    },
                    text: {
                        dataType: "string",
                        title: "Text",
                        validation: { required: true },
                        config: {
                            markdown: true
                        }
                    },
                    // products: {
                    //     title: "Products",
                    //     dataType: "array",
                    //     of: {
                    //         dataType: "reference",
                    //         path: "products",
                    //         previewProperties: ["name", "main_image"]
                    //     }
                    // }
                }
            }
        }),

        publishedAt: buildProperty({
            title: "Publish date",
            dataType: "timestamp"
        }),
        reviewed: buildProperty({
            title: "Reviewed",
            dataType: "boolean"
        }),
        status: buildProperty({
            title: "Status",
            validation: { required: true },
            dataType: "string",
            columnWidth: 140,
            config: {
                enumValues: {
                    published: "Published",
                    draft: "Draft"
                }
            }
        }),
        tags: buildProperty({
            title: "Tags",
            description: "Example of generic array",
            validation: { required: true },
            dataType: "array",
            of: {
                dataType: "string",
                config: {
                    previewAsTag: true
                }
            }
        })
    },
    defaultValues: {
        status: "draft",
        // tags: ["default tag"]
    }
});

/**
 * Sample field that will be added to the export
 */
export const sampleAdditionalExportColumn: ExportMappingFunction = {
    key: "extra",
    builder: async ({ entity }) => {
        await new Promise(resolve => setTimeout(resolve, 100));
        return "Additional exported value " + entity.id;
    }
};

import React from "react";
import ReactDOM from "react-dom";

import SampleApp from "./SampleApp/SampleApp";
// import { CustomCMSApp } from "./CustomCMSApp";

ReactDOM.render(
    <SampleApp/>,
    document.getElementById("root")
);

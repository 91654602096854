import { buildSchema, buildProperty, ExportMappingFunction } from "@camberi/firecms";

export const usersSchema = buildSchema({
    name: "User",
    properties: {
        firstName: {
            title: "firstName",
            dataType: "string",
            validation: {
                required: true
            }
        },
        lastName: {
            title: "lastName",
            dataType: "string"
        },
        email: {
            title: "Email",
            dataType: "string",
            validation: {
                email: true
            }
        },
        photoURL: buildProperty({
            title: "PHOTO URL",
            dataType: "string",
            description: "Recommended size (200 X 140)",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storeUrl: true,
                    storagePath: `images/${new Date().toLocaleDateString('en-CA')}/${new Date().getTime()}`,
                    acceptedFiles: ["image/*"],
                    metadata: {
                        cacheControl: "max-age=1000000"
                    }
                }
            }
        }),
        address: {
            title: "address",
            dataType: "string"
        },
        currentHeight: {
            title: "currentHeight",
            dataType: "number"
        },
        heightUnit: buildProperty({
            title: "Height Unit",
            validation: { required: true },
            dataType: "string",
            columnWidth: 140,
            config: {
                enumValues: {
                    cm: "CM",
                    ft: "FT"
                }
            },
        }),
        currentWeight: {
            title: "currentWeight",
            dataType: "number"
        },
        weightUnit: buildProperty({
            title: "Weight Unit",
            validation: { required: true },
            dataType: "string",
            columnWidth: 140,
            config: {
                enumValues: {
                    kg: "KG",
                    lb: "LB"
                }
            },
        }),
        dateOfBirth: {
            title: "dateOfBirth",
            dataType: "string"
        },
        gender: buildProperty({
            title: "Gender",
            validation: { required: true },
            dataType: "string",
            columnWidth: 140,
            config: {
                enumValues: {
                    male: "Male",
                    female: "Female",
                    rather_not_say: "Rather Not Say",
                    unknown: "Unknown",
                }
            },
        }),
        isArchived: {
            title: "isArchived",
            dataType: "boolean"
        },
        phoneNumber: {
            title: "phoneNumber",
            dataType: "string"
        },
        subscription: {
            title: "Subscription",
            dataType: "number"
        },
        subscriptionEndsAt: {
            title: "subscriptionEndsAt",
            dataType: "string"
        },
        timezone: {
            title: "timezone",
            dataType: "string"
        },
        userType: {
            title: "userType",
            dataType: "number"
        },
        createdAt: {
            title: "createdAt",
            dataType: "timestamp"
        },
        createdBy: {
            title: "createdBy",
            dataType: "string"
        },
        updatedAt: {
            title: "updatedAt",
            dataType: "string"
        },
        updatedBy: {
            title: "updatedBy",
            dataType: "string"
        },
        userGroup: buildProperty({
            title: "Type",
            validation: { required: true },
            dataType: "number",
            columnWidth: 140,
            config: {
                enumValues: {
                    1: "Live",
                    0: "Test",
                }
            },
        }),
    },
    defaultValues: {
        userGroup: 1,
    }
});

/**
 * Sample field that will be added to the export
 */
export const userAdditionalExportColumn: ExportMappingFunction = {
    key: "dateOfBirth",
    builder: async ({ entity }) => {
        // console.log('Date '+date.toString());
        // await new Promise(resolve => setTimeout(resolve, 100));
        if (entity.values.dateOfBirth) {
            var date = new Date(entity.values.dateOfBirth.toDate());
            console.log(date.toString());
            return date.toString();
        } else {
            return '';
        }
    }
};

export const userAgeColumn: ExportMappingFunction = {
    key: "Age",
    builder: async ({ entity }) => {
        // console.log('Date '+date.toString());
        // await new Promise(resolve => setTimeout(resolve, 100));
        if (entity.values.dateOfBirth) {
            var today = new Date();
            var birthDate = new Date(entity.values.dateOfBirth.toDate());
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            console.log(age)
            return age.toString();
        } else {
            return '';
        }
    }
};

export const attendeesSchema = buildSchema({
    name: "Users",
    properties: {
        name: {
            title: "name",
            dataType: "string",
            validation: {
                required: true
            }
        },
        hr: {
            title: "Heart Rate",
            dataType: "string",
            validation: {
                required: true
            }
        },
        calorie: {
            title: "calorie",
            dataType: "string",
            validation: {
                required: true
            }
        },
        createdAt: {
            title: "End Date",
            dataType: "timestamp",
            columnWidth: 250,
        },
    }
});
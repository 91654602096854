import { buildSchema, buildProperty, ExportMappingFunction } from "@camberi/firecms";

/**
 * This is a schema that is generated without passing an explicit type,
 * but it is inferred correctly since we are using `buildProperty` in each property
 */
export const videoSchema = buildSchema({
    name: "Video",
    properties: {
        videoName: buildProperty({
            title: "Video Name",
            validation: { required: true },
            dataType: "string"
        }),
        thumbnailUrl: buildProperty({
            title: "Thumbnail Image",
            description: "Recommended size (810 X 654)",
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "images",
                    acceptedFiles: ["image/*"],
                    storeUrl: true,
                    metadata: {
                        cacheControl: "max-age=1000000"
                    }
                }
            }
        }),
        category: {
            title: "Category",
            description: "Category",
            dataType: "string",
        },
        description: buildProperty({
            title: "Description",
            dataType: "string"
        }),
        hlsExternalUrl: buildProperty({
            title: "HLS External Video URL",
            dataType: "string"
        }),
        externalUrl: buildProperty({
            title: "External Video URL",
            dataType: "string"
        }),
        videoUrl: buildProperty({
            title: "Video URL",
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "video",
                    storagePath: "videos",
                    acceptedFiles: ["video/*"],
                    storeUrl: true,
                    metadata: {
                        cacheControl: "max-age=1000000"
                    }
                }
            }
        }),
        tags: buildProperty({
            title: "Tags",
            description: "Example of generic array",
            validation: { required: true },
            dataType: "array",
            of: {
                dataType: "string",
                // validation: { required: true },
                config: {
                    previewAsTag: true
                }
            }
        }),
        isFree: buildProperty({
            title: "isFree",
            dataType: "boolean"
        }),
        publishDate: buildProperty({
            title: "Publish date",
            dataType: "timestamp"
        }),
        status: buildProperty({
            title: "Status",
            validation: { required: true },
            dataType: "string",
            columnWidth: 140,
            config: {
                enumValues: {
                    published: "Published",
                    draft: "Draft"
                }
            }
        }),
        colour: buildProperty({
            title: "Colour",
            validation: { required: true },
            dataType: "string"
        }),
    },
    defaultValues: {
        status: "draft",
        colour: "E03CA3",
    }
});

/**
 * Sample field that will be added to the export
 */
export const sampleAdditionalExportColumn: ExportMappingFunction = {
    key: "extra",
    builder: async ({ entity }) => {
        await new Promise(resolve => setTimeout(resolve, 100));
        return "Additional exported value " + entity.id;
    }
};
